export default class ConsignatariaService {
  constructor(http) {
    this._http = http
  }

  async getConsignatarias() {
    const { data } = await this._http.get('/api/consignatarias/lista')
    return data
  }

  async getListaConsignatariasAtivas() {
    const { data } = await this._http.get(
      `/api/consignatarias/list-ativas-disponiveis`,
    )
    return data
  }

  async getConsignatariaLogada() {
    const { data } = await this._http.get(`/api/consignatarias/usuario`)
    return data
  }

  async saveConsignataria(consignataria) {
    if (consignataria.id) {
      return await this._http.put('/api/consignatarias', consignataria)
    } else {
      return await this._http.post('/api/consignatarias', consignataria)
    }
  }

  async deleteConsignataria(consignataria) {
    await this._http.delete(`/api/consignatarias/${consignataria.id}`)
  }

  async getConsignataria(id) {
    const { data } = await this._http.get(`/api/consignatarias/${id}`)
    return data
  }

  async getConsignatariasUsuarioGestaoEntidade() {
    const { data } = await this._http.get(
      '/api/consignatarias/usuarioGestaoEntidade',
    )
    return data
  }

  async getConsignatariasAtivas() {
    const { data } = await this._http.get('/api/consignatarias/ativas')
    return data
  }
}
